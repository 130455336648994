import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Essential oils are not essential to humans but are essence or concentrates of plants or plant parts. Essential oils of various plants can have pro-convulsive effects. Notable among them are eucalyptus and camphor. Camphor and eucalyptus oil have been implicated as a cause of seizure,
epilepsy, migraine and cluster headache. Both camphor and eucalyptus contain 1-8 cineole, a monoterpene, which has been proved in animal models to be epileptogenic. These essential oils are commonly present in various over the counter balms and oils and are often used by people for common ailments like headache, common cold and backache.`}</p>
    <p>{`A group of neurologists from three major hospitals in Bangalore, south India described 10 cases of eucalyptus oil inhalation induced seizures.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` They also published a paper on Essential Oil Related Seizures due to balms and various preparations containing the mixture of essential oils of eucalyptus and camphor in the recently in the journal epilepsy research.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` They observed that many of the cases of the so called “idiopathic seizures” are indeed induced and provoked by essential oils of eucalyptus and camphor. In their case series it was found that inhalation, ingestion and even topical application can trigger seizure. Surprisingly the commonest mode of exposure was topical application on the fore head, face and neck. Physicians who are not aware of the pro-convulsant properties of these essential oils, rarely enquire about the exposure to these in their history taking.`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{` They may falsely treat an essential oil induced seizure as idiopathic seizure. Essential oil exposure also is an important cause of break through seizures. If you do not identify the true cause, patients may be falsely labeled as “refractory seizure.”`}</p>
    <p>{`A survey of the literature shows essential oils of 11 plants to be powerful convulsants.`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{` The essential oils with pro-convulsant effects are eucalyptus, camphor, fennel, hyssop, pennyroyal, rosemary, sage, savin, tansy, thuja, turpentine, and wormwood. They contain highly reactive
monoterpene ketones, such as cineole, pinocamphone, thujone, pulegone, sabinylacetate and fenchone.  `}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup><sup>{`,`}</sup><sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{`    Camphor is a known convulsant for the past 500 years and was used to be given both orally and by injection to treat schizophrenia and bipolar disorder. Common over-the-counter medications which contain the essential oils of eucalyptus and camphor are Vicks, Amrutanjan, Tiger Balm, Zandu balm, Axe oil, Olbas oil, Equate etc. These herbal products tend to be used and quite often abused to treat common ailments such as cold and headache by both the people with epilepsy and general public, who are oblivious to the epileptogenic effects
of these products. People often use them thinking they are natural and safe. People also presume topical applications do not result in systemic absorption. It is high time that public especially those with seizure and epilepsy should be counseled to avoid these preparations which
contain essential oils with pro-convulsant properties. These cases of essential oil related seizures should sensitize commercial companies and regulatory authorities to put labels on products with pro-convulsant essential oils stating “potentially pro-convulsant and to be avoided by people with epilepsy”. This may prevent many cases of essential oil related seizures especially those secondary to usage of camphor and eucalyptus.`}</p>
    <p>{`The researchers have classified essential oil related seizures into Essential Oil Induced Seizures (seizure primarily caused by essential oil exposure) and Essential Oil Provoked Seizures (break through seizure in a known case of epilepsy). Without the necessary knowledge of the epileptogenic potential of the essential oils, these cases would have been misdiagnosed as idiopathic seizures and the usage of these potentially deleterious products would have gone unnoticed if not for specific inquiry regarding their usage. Most physicians in the world do not check for exposure to eucalyptus oil or camphor for the lack of mention in textbooks of medicine and neurology. Oblivious to the epileptogenic nature of these essential oils, the patients would have continued to use them, possibly causing their seizures to worsen and prove to be refractory
to prophylactic treatment. The proportion of seizures resulting from preventable exposure to eucalyptus oil and camphor is unknown. However, a significant amount of seizure burden and unnecessary anti-epileptic treatment, which carries side-effects, may be prevented by spreading
awareness about the epileptogenic potential of these substances. Essential oil related seizures may turn out to be the most unrecognized preventable cause of seizure.
Essential oils are also implicated in worsening migraine.`}<sup parentName="p" {...{
        "id": "fnref-6"
      }}><a parentName="sup" {...{
          "href": "#fn-6",
          "className": "footnote-ref"
        }}>{`6`}</a></sup>{` In case report recently published, a 14-year-old boy with chronic daily headache of 1-year duration, refractory to four antimigraine drugs, was found to be using a balm called Amruthanjan (10% camphor and 14.5% eucalyptus)
daily on his forehead to relieve headache. Patient had complete relief of headache within 2 weeks of stopping the balm application. All his anti-migraine drugs were tapered and stopped over a period of 3 months. At 1-year follow-up, he was headache free. Essential oils containing tooth
pastes are recently been implicated in causing cluster attacks. Two cases were reported and in one the cluster attack was precipitated by re-challenge with the same tooth paste.`}<sup parentName="p" {...{
        "id": "fnref-7"
      }}><a parentName="sup" {...{
          "href": "#fn-7",
          "className": "footnote-ref"
        }}>{`7`}</a></sup></p>
    <p>{`More studies are needed to be done in this field. But till then, it will be prudent to enquire about exposure to these pro-convulsant essential oils in all patients with migraine and clusterheadaches.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Thomas Mathew, Vikram Kamath, R Shivakumar, Meghana Srinivas, Prarthana Hareesh, Rakesh Jadav, Sreekanta Swamy. Eucalyptus oil inhalation–induced seizure: A novel, under-recognized, preventable cause of acute symptomatic seizure. Epilepsia Open 2017; 2(3):350–354.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Mathew T, K John S, Kamath V, Kumar R S, Jadav R, Swamy S, Adoor G, Shaji A, Nadig R, Badachi S, D Souza D, Therambil M, Sarma GRK, J Parry G. Essential oil related seizures (EORS): A multi-center prospective study on essential oils and seizures in adults. Epilepsy Res. 2021 Jul;173:106626. doi: 10.1016/j.eplepsyres.2021.106626. Epub 2021 Mar 26. PMID: 33813360.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`Samuels N, Finkelstein Y, Singer S R, Oberbaum M. Herbal medicine and epilepsy: Proconvulsive effects and interactions with antiepileptic drugs. Epilespia 2008; 49(3):373-380.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`Burkhard PR, Burkhardt K, Haenggeli CA, et al. Plant-induced seizures: reappearance of an old problem. J Neurol 1999;246:667–670.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>{`Tyler A. Bahr, Damian Rodriguez, Cody Beaumont, Kathryn Allred. The Effects of Various Essential Oils on Epilepsy and Acute Seizure: A Systematic Review. Evidence-Based Complementary and Alternative Medicine. 2019; `}<a parentName="li" {...{
            "href": "https://doi.org/10.1155/2019/6216745"
          }}>{`https://doi.org/10.1155/2019/6216745`}</a><a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>{`Mathew T, John SK. An unsuspected and unrecognized cause of medication overuse headache in a chronic migraineur—essential oil-related medication overuse headache: A case report. Cephalalgia Reports. January 2020. doi:10.1177/2515816319897054`}<a parentName="li" {...{
            "href": "#fnref-6",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-7"
        }}>{`Mathew T, John SK, Javali MV. Essential oils and cluster headache: insights from two cases. BMJ Case Rep. 2021 Aug 9;14(8):e243812. doi: 10.1136/bcr-2021-243812. PMID:34373243; PMCID: PMC8354251.`}<a parentName="li" {...{
            "href": "#fnref-7",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      